* {
  margin: 0;
  padding: 0;
  list-style-type: none !important;
  text-decoration: none !important;
  box-sizing: border-box;
}
.min_65_vh {
  min-height: 65vh;
}
.gallery_background {
  background-image: url(./assets/image/png/gallery_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/*------Testimonials--------*/

.box1 {
  background: #ffffff;
  border-radius: 8px;
  padding: 30px 10px;
  border: 1px solid #e4e4e4;
}
.slick-slide .box1 {
  opacity: 0.7 !important;
}
.slick-slide.slick-active.slick-center.slick-current .box1 {
  opacity: 1 !important;
  transform: translateY(-20px);
  transition: all 300ms linear;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.08);
  border: 1px solid transparent;
}
.Testimonials_slider .slick-slider .slick-list {
  overflow: visible !important;
}
.left_arrow {
  background: #ff9400;
  border-radius: 1.5px;
  padding: 8px 15px;
  transition: all 400ms linear;
}
.left_arrow:hover {
  background: #ff9500b1;
}
.right_arrow {
  background: #ff9400;
  border-radius: 1.5px;
  padding: 8px 15px;
  transition: all 400ms linear;
}
.right_arrow:hover {
  background: #ff9500b1;
}
/*------Workshop-------*/

.workshop_slider .slick-slider .slick-list {
  overflow: visible !important;
}
.porttitor {
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(7px);
  padding: 8px;
  border-radius: 2px;
  bottom: 8px;
  left: 18px;
  opacity: 0;
}
.workshop_slider .slick-slider .slick-track {
  display: flex;
  align-items: center;
}
.slick-slide.slick-active.slick-center.slick-current .porttitor {
  opacity: 1 !important;
}
/*-------OurLabourers--------*/
.Labourers_bg_img {
  background-image: url("./assets/image/png/Labourers_bg_img.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.Labourers_slider .slick-slider .slick-list {
  overflow: hidden !important;
}
.img_name {
  background: #0a3868;
  border-radius: 4px;
  padding: 10px;
  bottom: 14px;
  right: 14px;
}
.box1 {
  padding: 10px 16px;
}

@media (min-width: 576px) {
  .box1 {
    padding: 20px 37px;
  }
}

@media (min-width: 768px) {
  .box1 {
    padding: 20px 57px;
  }
}

@media (min-width: 992px) {
  .box1 {
    padding: 20px 28px;
  }
}

@media (min-width: 1200px) {
  .box1 {
    padding: 40px 95px;
  }
}
