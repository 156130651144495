@import url(./main.css);
@import url(./style.css);
@import url("https://fonts.googleapis.com/css2?family=Cardo:wght@400;700&family=Noto+Sans:wght@400;500;600;700;800&family=Oswald:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  list-style-type: none !important;
  text-decoration: none !important;
  box-sizing: border-box;
}
.dropdown-menu {
  min-width: unset !important;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: black !important;
  opacity: 0.7;
}
:root {
  /* font-size */
  --fs-sm: 14px;
  --fs-base: 16px;
  --fs-md: 18px;
  --fs-2xl: 20px;
  --fs-4xl: 30px;
  --fs-6xl: 34px;
  /* color */
  --clr-primary: #0a3868;
  --clr-secondary: #ff9400;
  --clr-light: #f3f3f3;
  /* font-family */
  --ff-cardo: "Cardo";
  --ff-oswald: "Oswald";
  --ff-noto: "Noto Sans";
}
.overflow_x_hidden {
  overflow-x: hidden;
}
/* font-family */
.ff_cardo {
  font-family: var(--ff-cardo);
}
.ff_noto {
  font-family: var(--ff-noto);
}
.ff_oswald {
  font-family: var(--ff-oswald);
} /* text-colors */
.text_primary {
  color: var(--clr-primary);
}
.text_secondary {
  color: var(--clr-secondary);
} /* background-color */
.bg_primary {
  background-color: var(--clr-primary);
}
.bg_secondary {
  background-color: var(--clr-secondary);
} /* font-weight */
.fw_regular {
  font-weight: 400;
}
.fw_medium {
  font-weight: 500;
}
.fw_semibold {
  font-weight: 600;
}
.fw_bold {
  font-weight: 700;
}
.fw_extrabold {
  font-weight: 800;
} /* opacity */
.opacity_07 {
  opacity: 0.7 !important;
}
.opacity_06 {
  opacity: 0.6 !important;
} /* font-size */
.fs_sm {
  font-size: var(--fs-sm);
}
.fs_base {
  font-size: var(--fs-base);
}
.fs_md {
  font-size: var(--fs-md);
}
.fs_2xl {
  font-size: var(--fs-2xl);
}
.fs_4xl {
  font-size: var(--fs-4xl);
}
.fs_6xl {
  font-size: var(--fs-6xl);
} /* Common-btn */
.primary_btn {
  padding: 16px 32px;
  border-radius: 8px;
  white-space: nowrap;
  background-color: var(--clr-primary);
  border: 1px solid transparent;
  color: #fff;
  transition: linear 250ms;
}
.primary_btn:hover,
.secondary_btn:hover {
  background-color: var(--clr-secondary);
  color: #fff;
}
.secondary_btn {
  padding: 14px 28px;
  border-radius: 8px;
  white-space: nowrap;
  background-color: var(--clr-primary);
  border: 1px solid transparent;
  color: #fff;
  transition: linear 250ms;
}
.common_para {
  font-size: var(--fs-base);
  font-weight: 400;
  color: #000;
  opacity: 0.7 !important;
  font-family: var(--ff-noto);
}
.common_heading {
  font-size: var(--fs-4xl);
  font-weight: 600;
  font-family: var(--ff-oswald);
}
.nav_links {
  transition: linear 250ms;
  position: relative;
}
.nav_links:hover {
  color: var(--clr-primary) !important;
  opacity: 1 !important;
}
.nav_links:hover button {
  color: var(--clr-primary) !important;
  opacity: 1 !important;
}
.dropdown-toggle::after {
  background: url(./assets/image/png/service-btn.png);
  border: unset !important;
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 13px;
  height: 7px;
}
.dropdown-menu {
  opacity: 0;
  transition: 200ms linear;
  pointer-events: none;
  display: block !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  pointer-events: all;
}
.nav_links::after {
  position: absolute;
  content: "";
  height: 3px;
  bottom: -30%;
  right: -8%;
  border-radius: 0 0 2px 2px;
  background-color: var(--clr-primary);
  width: 0;
  transition: ease-out 300ms;
}
.nav_links:hover::after {
  width: 116%;
}
ul li:nth-of-type(3) .nav_links:hover::after {
  width: 0 !important;
}
.showNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  opacity: 0 !important;
  min-height: 100vh;
  background-color: #fff;
  pointer-events: none;
  transition: linear 350ms;
  z-index: 99;
  background-color: #0a3868;
}
.hideNav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1 !important;
  min-height: 100vh;
  background-color: #fff;
  pointer-events: all;
  transition: linear 350ms;
  z-index: 99;
}
.menu_btn {
  width: 35px;
  height: 25px;
  border: 1.5px solid black;
  background-color: transparent;
  border-radius: 4px;
  padding: 0 4px;
}
.menu_line {
  width: 100%;
  display: inline-block;
  border-radius: 4px;
  height: 3px;
  background-color: var(--clr-primary);
}
.page_logo {
  width: 70px;
}
nav {
  background-color: var(--clr-light);
}
.landing_hero {
  background: url(./assets/image/png/hero-landing-img.webp);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.work_blue_box_two,
.work_blue_box {
  transition: linear 250ms;
}
.work_img_box:hover .work_blue_box_two {
  bottom: 10% !important;
  right: 10% !important;
}
.work_img_box:hover .work_blue_box {
  left: 10% !important;
}
/* Back to top */
.back-to-top {
  position: fixed;
  bottom: 3%;
  right: 20px;
  z-index: 9999;
  color: var(--white);
  background-color: var(--clr-secondary);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
  font-weight: 500;
}
.up-down-anim {
  animation: up-down 5s linear infinite;
}
@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}
@media (min-width: 576px) {
  :root {
    --fs-md: 20px;
    --fs-2xl: 22px;
    --fs-4xl: 34px;
    --fs-6xl: 44px;
  }
  .page_logo {
    width: 110px;
  }
}
@media (min-width: 992px) {
  :root {
    --fs-md: 20px;
    --fs-2xl: 24px;
    --fs-4xl: 40px;
    --fs-6xl: 54px;
  }
}
@media (min-width: 1200px) {
  header {
    min-height: 100vh;
  }
}
@media (min-width: 1400px) {
  header {
    min-height: 100vh;
  }
  :root {
    --fs-md: 20px;
    --fs-2xl: 24px;
    --fs-4xl: 40px;
    --fs-6xl: 64px;
  }
}
.testimonials_rating {
  cursor: pointer;
}
.slick-track {
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
  display: flex !important;
}
.slick-slide > div {
  display: flex;
}
.testimonial_profile {
  padding: 4px 2px;
  border-radius: 4px;
  background-color: #000;
}
