* {
  margin: 0;
  padding: 0;
  list-style-type: none !important;
  text-decoration: none !important;
  box-sizing: border-box;
}

.Contact_us {
  max-width: 430px;
}

::placeholder {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #000000;
  opacity: 0.6;
  padding-bottom: 10px;
  padding-left: 20px;
}

.send_btn {
  width: 247px;
}

.bg_img_footer {
  background-image: url(../src/assets/image/png/Footer_bg_img.png);
  background-size: cover;
  background-repeat: no-repeat;
}

footer ul li a:hover {
  color: var(--clr-primary) !important;
  transition: all 0.3s linear;
}

footer div a:hover {
  transform: scale(1.2);
}

footer div a {
  transition: all 0.2s linear;
}

.cursor_pointer {
  cursor: pointer;
}

.z_index_1 {
  z-index: 1;
}

.z_index_n_1 {
  z-index: -1;
}

.bg_img_contact {
  background-image: url(../src/assets/image/png/contact_us.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: 152px;
}

.Now_contact_bg_img {
  background-image: url(./assets/image/png/contact_us_now.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.Contact_us_para {
  max-width: 440px;
}

.transition_linear {
  transition: all 200ms linear;
}

.transform_scale:hover {
  transform: scale(1.2) !important;
}

#placeholder_boder {
  width: 100%;
  height: 46px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}
form textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}
form textarea {
  resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (max-width: 576px) {
  .line_footer {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0px;
    bottom: 63px;
    background: #ff9400;
    opacity: 0.3;
  }
}

@media (min-width: 576px) {
  .line_footer {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0px;
    top: 642px;
    background: #ff9400;
    opacity: 0.3;
  }
}

@media (min-width: 768px) {
  .line_footer {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0px;
    top: 635px;
    background: #ff9400;
    opacity: 0.3;
  }
}

@media (min-width: 992px) {
  .line_footer {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0px;
    top: 423px;
    background: #ff9400;
    opacity: 0.3;
  }

  .footer_li {
    max-width: 100%;
  }
}

@media (min-width: 1200px) {
  .line_footer {
    position: absolute;
    width: 100%;
    height: 4px;
    left: 0px;
    top: 415px;
    background: #ff9400;
    opacity: 0.3;
  }
}
