* {
  margin: 0;
  padding: 0;
  list-style-type: none !important;
  text-decoration: none !important;
  box-sizing: border-box;
}
.service_background {
  background-image: url(./assets/image/png/service.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.background_engin {
  background-image: url(./assets/image/png/bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.shadow {
  background: #ffffff;
  border-radius: 8px;
}
.slick-next:before {
  display: none !important;
}
.slick-prev:before {
  display: none !important;
}
.max_width {
  max-width: 1100px !important;
}
.slick-dots li button:before {
  font-size: 14px !important;
  margin-top: 40px;
  color: #f8f9fa !important;
  opacity: 1 !important;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #ff9400 !important;
}
.about_background {
  background: #f5f5f5;
}
.hero_background {
  background-image: url(./assets/image/png/hero.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.mh_60 {
  min-height: 60vh;
}
.work_hover {
  transition: linear 250ms;
}
.work_hover:hover {
  color: #ff9400 !important;
}
.white_space {
  white-space: nowrap;
}
.mh_246 {
  min-height: 246px;
}
